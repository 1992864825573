@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'roboto', sans-serif;
}

.policyLink {
	text-decoration: none;
	color: goldenrod;
}
.policyLink:hover {
	color: gold;
}
.brdor {
	border: 1px solid red;
}
.char {
	color: goldenrod;
}
.font-dance {
	font-family: 'Dancing Script', cursive;
}

.styleLink {
	color: white;
	transition: 0.3s ease-in;
}
.styleLink:hover {
	color: goldenrod;
}

/* spinner remove */
input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
