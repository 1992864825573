.navigation-menu {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
.navigation-menu li {
	margin: 0px 16px;
}
.navigation-menu li a {
	text-decoration: none;
	font-size: 1.1rem;
	letter-spacing: 2px;
	color: white;
}
.navigation-menu li :hover {
	color: goldenrod;
}
.active {
	color: goldenrod !important;
	font-weight: bold !important;
}
/* mobile view */
.mobile-navigation {
	list-style: none;
}
.mobile-navigation li {
	margin-top: 20px;
}
.mobile-navigation li a {
	text-decoration: none;
	font-size: 1.4rem;
	color: black;
}
