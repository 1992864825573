.embla {
	overflow: hidden;
	height: 95vh;
	/* border: 2px solid blue; */
}
.embla__container {
	display: flex;
	height: 100%;
}
.embla__slide {
	flex: 0 0 100%;
	width: 100%;
	min-height: 1000px;
	/* border: 3px solid yellow; */
}
.embla__slide img {
	width: 100%;
	height: 100%;
	/* min-height: 400px;
	max-height: 810px; */
	object-position: left(10px);
	object-fit: cover;
	display: block;
	margin-bottom: 100px;
}
